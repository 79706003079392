// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-video-js": () => import("/opt/build/repo/src/templates/SingleVideo.js" /* webpackChunkName: "component---src-templates-single-video-js" */),
  "component---src-templates-subject-page-js": () => import("/opt/build/repo/src/templates/SubjectPage.js" /* webpackChunkName: "component---src-templates-subject-page-js" */),
  "component---src-templates-blog-page-js": () => import("/opt/build/repo/src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-promo-page-js": () => import("/opt/build/repo/src/templates/PromoPage.js" /* webpackChunkName: "component---src-templates-promo-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activate-js": () => import("/opt/build/repo/src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-blogs-js": () => import("/opt/build/repo/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("/opt/build/repo/src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-forgot-password-js": () => import("/opt/build/repo/src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-promos-js": () => import("/opt/build/repo/src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-purchase-subscription-js": () => import("/opt/build/repo/src/pages/purchase-subscription.js" /* webpackChunkName: "component---src-pages-purchase-subscription-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("/opt/build/repo/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-unpublished-js": () => import("/opt/build/repo/src/pages/unpublished.js" /* webpackChunkName: "component---src-pages-unpublished-js" */),
  "component---src-pages-user-js": () => import("/opt/build/repo/src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

